<template>
  <div>
    <aRow :gutter="20">
      <aCol :span="24">
        <InvoicePaxesTable
          :contractList="contractList"
          :loading="loadingContractList"
        />

        <aDivider />

        <div class="invoice-value">
          Valor da fatura:
          <b class="cgreen">
            {{ event.invoice.value | formatPricePtBr }}
          </b>
          <a-divider type="vertical" />
          Código fatura:
          <a-tag
            ><b>{{ event.invoice.invoice_code }}</b></a-tag
          >
        </div>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import InvoicePaxesTable from "@/components/finances/sections/InvoicePaxesTable.vue";

export default {
  components: { InvoicePaxesTable },
  name: "EventInvoiceSalesTab",
  props: {
    event: Object,
  },
  mixins: [formatThings],
  data() {
    return { contractList: [], loadingContractList: false };
  },
  mounted() {
    this.loadingContractList = true;
    this.$http
      .get(
        `/contract-meta/list?page=1&per_page=999&order=desc&order-by=id&info_name=invoice_id&info_value=${this.event.raw.invoice_id}`
      )
      .then(({ data }) => {
        this.contractList = data.data.map((item) => {
          return item.contract;
        });
      })
      .finally(() => (this.loadingContractList = false));
  },
};
</script>

<style lang="sass">
.generate-invoices-table td
  padding: 4px 3px 4px 3px !important
.generate-invoices-table thead tr th
  padding: 4px 3px 4px 3px !important

.generate-invoices-table
  .ant-table-pagination
    margin-bottom: 0 !important
    padding: 0
</style>
